import Vue from "vue";
// axios
import axios from "axios";

const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	// baseURL: 'http://cabr-v2.int-star.cn/api/',
	timeout: 60000,
	// headers: {'X-Custom-Header': 'foobar'}
	//responseType: 'json',
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
